(function() {

    dmx.Formatters('array', {

        /**
         * @param {Object[]} collection
         * @param {Object} params
         * @param {string} params.name - new column name
         * @param {string} params.value - value of the column (expression)
         * @param {boolean} [params.overwrite=false] - overwrite existing column
         */
        collectionAddColumn: function(collection, params) {
            var exp = (typeof params.value == 'string' && params.value.indexOf('{{') != -1)

            return collection.map(function(row, i) {
                row = dmx.clone(row);

                if (params.overwrite || !row[params.name]) {
                    if (exp) {
                        var scope = Object.assign({
                            $key: i,
                            $index: i,
                            $value: row
                        }, row);
                        row[params.name] = dmx.parse(params.value, new dmx.DataScope(scope, this));
                    } else {
                        row[params.name] = params.value;
                    }
                }
                
                return row;
            }, this);
        },

        /**
         * @param {Object[]} collection
         * @param {Object} params
         * @param {Object} [params.row] - row data
         * @param {number} [params.add=1] - number of rows
         */
        collectionAddRows: function(collection, params) {
            collection = dmx.clone(collection);

            var add = params.add || 1;

            for (var i = 0; i < add; i++) {
                collection.push(params.row || {});
            }

            return collection;
        },

        /**
         * @param {Object[]} collection
         * @param {Object} params
         * @param {string} params.column - column to remove
         */
        collectionRemoveColumn: function(collection, params) {
            return collection.map(function(row) {
                row = dmx.clone(row);

                delete row[params.column];

                return row;
            });
        },

        /**
         * @param {Object[]} collection
         * @param {Object} params
         * @param {string} params.name - new column name
         * @param {string[]} params.columns - array of columns to merge
         * @param {string} [params.delimiter] - delimeter
         */
        collectionMergeColumns: function(collection, params) {
            return collection.map(function(row) {
                row = dmx.clone(row);

                row[params.name] = params.columns.map(function(column) {
                    return row[column];
                }).join(params.delimiter || '');

                return row;
            });
        },

        /**
         * @param {Object[]} collection
         * @param {Object} params
         * @param {string} params.column - old column name
         * @param {string} params.name - new column name
         */
        collectionRenameColumn: function(collection, params) {
            return collection.map(function(row) {
                row = dmx.clone(row);

                if (row[params.column]) {
                    row[params.name] = row[params.column];
                    delete row[params.column];
                }

                return row;
            });
        },

        /**
         * @param {Object[]} collection
         * @param {Object} params
         * @param {string} params.column - column to split
         * @param {string[]} [params.names] - new column names
         * @param {string} [params.delimiter=,] - delimiter
         */
        collectionSplitColumn: function(collection, params) {
            return collection.map(function(row) {
                row = dmx.clone(row);

                if (typeof row[params.column] == 'string') {
                    var names = Array.isArray(params.names) ? params.names : [];
                    var values = row[params.column].split(params.delimiter || ',');
                    
                    delete row[params.column];
                    
                    values.forEach(function(value, i) {
                        var name = names[i] || params.column, n = 0;
                        while (row[name]) name = name + ' (' + (++n) + ')';
                        row[name] = value;
                    });
                }

                return row;
            });
        },

        /**
         * @param {Object[]} collection
         * @param {Object} params
         * @param {string} params.column - key column
         * @param {number} [params.keep=1] - number of duplicates to keep
         * @param {boolean} [params.merge=false] - merge duplicates
         * @param {string[]} [params.columns] - merge columns
         * @param {string} [params.delimiter=, ] - merge delimiter
         */
        collectionDedupe: function(collection, params) {
            var keep = params.keep || 1;
            var delimiter = params.delimiter || ', ';

            return collection.reduce(function(arr, current) {
                var dupes = arr.filter(function(row) { return row[params.column] === current[params.column]; });

                if (params.merge) {
                    if (dupes.length) {
                        params.columns.forEach(function(column) {
                            if (current[column]) {
                                dupes[0][column] += delimiter + current[column];
                            }
                        });
                    } else {
                        arr.push(current);
                    }
                } else if (dupes.length < keep) {
                    arr.push(current);
                }

                return arr;
            }, []);
        }

    });

})();